import {
  debounce,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { IOpportunity } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { Dispatch, useCallback, useMemo, useState, useEffect, useRef } from 'react';

interface IOppFilterProps {
  category: string | undefined;
  categories: SelectItem[];
  setCategory: Dispatch<string>;
  setFilteredOpps: Dispatch<React.SetStateAction<IOpportunity[]>>;
  opportunities: IOpportunity[];
  children?: React.ReactNode;
}

const StyledAdornment = styled(InputAdornment)(() => ({
  fieldset: {
    marginTop: '6px',
    marginBottom: '6px',
    borderTopWidth: '0px !important',
    borderBottomWidth: '0px !important',
    borderRightColor: 'rgba(0, 0, 0, 0.23) !important',
    borderRightWidth: '1px !important',
    borderLeftWidth: '0px !important',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
}));

export default function OppFilters({
  category,
  categories,
  setCategory,
  setFilteredOpps,
  opportunities,
  children,
}: IOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const workerRef = useRef<Worker | null>(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    workerRef.current = new Worker(new URL('./search-worker.ts', import.meta.url));
    workerRef.current.postMessage({
      type: 'BUILD_INDEX',
      data: { opportunities },
    });

    return () => workerRef.current?.terminate();
  }, [opportunities]);

  useEffect(() => {
    const handleWorkerMessage = (event: MessageEvent) => {
      if (event.data.type === 'SEARCH_RESULTS') {
        setFilteredOpps(event.data.data.map((index: number) => opportunities[index]));
      }
    };

    workerRef.current?.addEventListener('message', handleWorkerMessage);

    return () => {
      workerRef.current?.removeEventListener('message', handleWorkerMessage);
    };
  }, [opportunities, setFilteredOpps]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, cat: string) => {
        if ((cat === 'All' && value.length >= 3) || cat !== 'All') {
          workerRef.current?.postMessage({
            type: 'SEARCH',
            data: { searchValue: value, category: cat },
          });
        } else {
          setFilteredOpps(opportunities);
        }
      }, 200),
    [opportunities, setFilteredOpps],
  );

  useEffect(() => debouncedSearch.clear(), [debouncedSearch]);

  const handleCategoryChange = useCallback(
    (e: SelectChangeEvent<unknown>): void => {
      const selectedCat = e.target.value as string;
      setCategory(selectedCat);
      debouncedSearch(search, selectedCat);
    },
    [setCategory, debouncedSearch, search],
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newSearchValue = e.target.value;
      setSearch(newSearchValue);

      if (newSearchValue.trim() === '' && category === 'All') {
        debouncedSearch.clear();
        setFilteredOpps(opportunities);
      } else {
        debouncedSearch(newSearchValue, category || 'All');
      }
    },
    [debouncedSearch, category, opportunities, setFilteredOpps],
  );

  return (
    <Grid container item xs={12} sx={{ mb: !isMobile ? 5 : 2, mt: 5 }} gap={1}>
      <Grid item xs={12} sm={5}>
        <TextField
          name="search"
          label="Search"
          value={search}
          onChange={handleSearchChange}
          autoComplete="off"
          placeholder="Enter Keywords"
          size={isMobile ? 'medium' : 'small'}
          fullWidth
          InputProps={{
            startAdornment: (
              <StyledAdornment position="start">
                <FormSelect
                  value={category}
                  name="category"
                  size={isMobile ? 'medium' : 'small'}
                  items={categories}
                  onChange={handleCategoryChange}
                  sx={{
                    width: !isMobile ? '150px' : '100px',
                  }}
                />
              </StyledAdornment>
            ),
          }}
        />
      </Grid>
      {children}
    </Grid>
  );
}
