import { Dialog, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import CloseIcon from '@mui/icons-material/Close';

import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';

import ApplyExternalOpportunity from './ApplyExternalOpportunity';
import ApplyShouttOpportunity from './ApplyShouttOpportunity';
import { payWalled } from 'services/opportunityService';
import { pushToDataLayer } from 'utils/tagHelper';
import { beginCheckoutEvent } from 'global/constants';

interface IApplyDialogProps {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateOpportunity: (opportunity: IOpportunity) => void;
}

export default function ApplyDialog(props: IApplyDialogProps): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const authContext = useContext(AuthContext);
  const subscriptionActive: boolean = authContext.user?.subscriptionActive ?? false;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const createSubscriptionSession = () => {
    setLoading(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout(`${window.location.href}`)
      .then(res => {
        window.location.href = res;
        setLoading(false);
      })
      .catch(showError);
  };

  const updateApplyOnClose = () => {
    props.onClose();
    setTimeout(() => {
      if (props.opportunity) {
        props.opportunity.applied = true;
        props.updateOpportunity(props.opportunity);
      }
    }, 500);
  };

  useEffect(() => {
    if (props.open && props.opportunity != null && !subscriptionActive) {
      payWalled(props.opportunity.id);
    }
  }, [props.opportunity, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      {!subscriptionActive && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              Upgrade Plan
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            {props.opportunity?.type === OpportunityType.External && (
              <Typography variant="body2">
                Upgrade to the premium plan to apply for this external opportunity. We spend over 40 hours per week to
                bring 1000+ curated opportunities per month from various sites accross the web so you don&apos;t have
                to.
              </Typography>
            )}
            {props.opportunity?.type === OpportunityType.Shoutt && (
              <Typography variant="body2">Upgrade to the premium plan to start applying for opportunities.</Typography>
            )}

            <Typography variant="body2">
              The Shoutt Premium plan costs {localCurrencySymbol}
              {price} / month with no long term contract and allows you to apply to unlimited opportunities each month.
              You can find more information on the features of the premium plan in{' '}
              <span
                style={{ textDecoration: 'underline', color: theme.palette.primary.dark, cursor: 'pointer' }}
                onClick={() => navigate('/subscriptions')}
              >
                your subscription.
              </span>
            </Typography>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
              Cancel
            </RoundButton>
            <RoundButton
              fullWidth={isMobileScreen}
              loading={loading}
              variant="contained"
              onClick={() => createSubscriptionSession()}
            >
              Upgrade Now
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
      {subscriptionActive && props.opportunity?.type === OpportunityType.External && (
        <ApplyExternalOpportunity
          open={props.open}
          opportunity={props.opportunity}
          updateApplyOnClose={updateApplyOnClose}
        />
      )}
      {subscriptionActive && props.opportunity?.type === OpportunityType.Shoutt && (
        <ApplyShouttOpportunity
          open={props.open}
          opportunity={props.opportunity}
          onClose={props.onClose}
          updateApplyOnClose={updateApplyOnClose}
        />
      )}
    </Dialog>
  );
}
