import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IOpportunity, OpportunityLocationType, OpportunityType } from 'global/interfaces/opportunity';

import { pushToDataLayer } from 'utils/tagHelper';
import { viewOpportunity } from 'global/constants';
import { opportunityViewed } from 'services/opportunityService';
import { AccessTime } from '@mui/icons-material';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: '16px',
  boxShadow: '0px 2px 12px 0px #1721551A',

  '&.MuiPaper-root': {
    borderRadius: '7px',
    padding: '16px',

    '&:before': {
      display: 'none',
    },
  },

  '&:last-of-type': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('md')]: {
    '&.MuiPaper-root': {
      '&:hover': {
        borderColor: '#4B56D8',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiPaper-root': {
      borderRadius: '8px',
      padding: '8px',
    },
  },
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  'svg[data-testid="ExpandMoreIcon"]': {
    display: 'inline-block',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandMoreIcon"]': {
    display: 'none',
  },
  'svg[data-testid="ExpandLessIcon"]': {
    display: 'none',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandLessIcon"]': {
    display: 'inline-block',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '24px',

  backgroundColor: theme.palette.black[200],
  borderRadius: '16px',
  color: theme.palette.black[500],
  cursor: 'pointer',
  padding: '4px 4px',
}));

const StyledShouttChip = styled(StyledChip)(() => ({
  backgroundColor: '#E7E2FE',
}));

interface IOpportunityAccordionProps {
  loggedIn?: boolean;
  opportunity: IOpportunity;
  children: React.ReactNode;
}

export default function OpportunityAccordion({
  opportunity,
  loggedIn,
  children,
}: IOpportunityAccordionProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onExpandContractOpportunity = (opportunity: IOpportunity, expanded: boolean): void => {
    if (expanded) {
      pushToDataLayer(viewOpportunity, {
        transaction_id: opportunity.id,
      });
      if (loggedIn) {
        opportunityViewed(opportunity.id);
      }
    }
  };

  return (
    <StyledAccordion
      elevation={10}
      disableGutters
      onChange={(_: any, expanded: boolean) => onExpandContractOpportunity(opportunity, expanded)}
    >
      <StyledAccordionSummary>
        <JustifiedBox>
          <Stack direction="column" gap={isMobile ? 0.75 : 1} width="100%">
            <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }} component={'div'}>
              {opportunity.title}
              {opportunity.type == OpportunityType.Shoutt && !isMobile && (
                <StyledShouttChip
                  label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                  size="small"
                  sx={{ ml: 1.5, mb: 0.5 }}
                />
              )}
              {opportunity.type == OpportunityType.External && !isMobile && (
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  title="We have sourced this opportunity from an external site. We spend 40+ hours a week curating opportunities from over 30 sources so you don't have to."
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '320px',
                        borderRadius: '12px',
                      },
                    },
                  }}
                >
                  <StyledChip
                    label={<Typography variant={'caption'}>External</Typography>}
                    size="small"
                    sx={{ ml: 1.5, mb: 0.5 }}
                  />
                </Tooltip>
              )}
            </Typography>
            {opportunity.type == OpportunityType.Shoutt && isMobile && (
              <Box>
                <StyledShouttChip
                  label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                  size="small"
                />
              </Box>
            )}
            {opportunity.type == OpportunityType.External && isMobile && (
              <Box>
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  leaveDelay={4000}
                  title="We have sourced this opportunity from an external site. We spend 40+ hours a week curating opportunities from over 30 sources so you don't have to."
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '320px',
                        borderRadius: '12px',
                      },
                    },
                  }}
                >
                  <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                </Tooltip>
              </Box>
            )}

            <Typography
              variant="body2"
              color={theme.palette.grey[500]}
              component={Box}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {opportunity.categories.map((category: string, index: number) => (
                <Box
                  key={index}
                  component="span"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {category}&nbsp;&nbsp;{'•'}&nbsp;&nbsp;
                </Box>
              ))}
              {opportunity.locationType == OpportunityLocationType.Remote ? 'Remote' : opportunity.location}
              {opportunity.budget && (
                <>
                  &nbsp;&nbsp;{'•'}&nbsp;&nbsp;
                  {opportunity.budget}
                </>
              )}
            </Typography>

            {isMobile && (
              <Stack direction={'row'} gap={0.5} minWidth={'100px'} mt={0.5}>
                <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                <Typography variant="body2" color={theme.palette.grey[500]}>
                  {opportunity.agoDesc}
                </Typography>
              </Stack>
            )}
          </Stack>
          {!isMobile && (
            <Stack direction={'row'} gap={0.5} minWidth={'100px'}>
              <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
              <Typography variant="body2" color={theme.palette.grey[500]}>
                {opportunity.agoDesc}
              </Typography>
            </Stack>
          )}
        </JustifiedBox>
      </StyledAccordionSummary>

      <AccordionDetails>
        <Stack direction="column" spacing={0} width="100%" gap={3}>
          <Typography variant={isMobile ? 'body2' : 'body1'} whiteSpace="break-spaces">
            {opportunity.description}
          </Typography>
          <Grid container gap={2}>
            {children}
          </Grid>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
}
